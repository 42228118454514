import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { CommonService } from '../../../shared/utils/imports';
@Component({
  selector: 'app-header-internal',
  templateUrl: './header-internal.component.html'
})
export class HeaderInternalComponent implements OnInit {
  activeMainTab = 'initial';
  activeSubTab = '';
  activeReportsTab = '';
  public showReportsDrop = '';
  public showActiveTab = 'initial';
  public tabName = '';
  public showTabDropdown = '';
  public dashboardName = 'Appointment Dashboard';
  public userRole;
  public userPrivilegeDetails;
  public userType;
  public sendBtnOption;
  isVisible: boolean = false;
  constructor(
    private readonly router: Router,
    private readonly common: CommonService
  ) {
    const secondsCounter = interval(100);
    secondsCounter.subscribe(n => {
      this.checkTabSelection();
      // this.internalPermisson();
    });
    this.userType = this.common.getUserType();
  }

  ngOnInit() {
    this.common.internalDashboardTabCheck.subscribe(value => {
      if (value) {
        this.common.setInternalDashboardTab(false);
      }
    });
    // this.common.notificationPermissionCheck.subscribe(value => {
    //   // if (value) {
    //   this.sendBtnOption = value;
    //   // }
    // });
  }


  /* Function for closing the dropdown on clicking outside */
  public closeDropdown(event: Event) {
    this.showTabDropdown = '';
    this.showReportsDrop = '';
    this.activeReportsTab = '';
  }
  gotoReportsTabs(tabName) {
    this.activeReportsTab = tabName;
    this.activeMainTab = 'user-drop';
    this.showTabDropdown = '';
    switch (tabName) {
      case 'user-activity':
        this.router.navigate(['dashboard/internal/reports/user-activity']);
        break;
      case 'res-turnaround-time':
        this.router.navigate(['dashboard/internal/reports/res-turnaround-time']);
        break;
      case 'appointment-overview':
        this.router.navigate(['dashboard/internal/reports/appointment-overview']);
        break;
      case 'user-access-report':
        this.router.navigate(['dashboard/internal/reports/user-access-report']);
        break;
      case 'pcp-report':
        this.router.navigate(['dashboard/internal/reports/pcp-report']);
        break;
      case 'pcp-update-request-report':
        this.router.navigate(['dashboard/internal/reports/pcp-update-request-report']);
        break;
      // case 'termsandconditions-request-report':
      //   this.router.navigate(['dashboard/internal/reports/termsandconditions-request-report']);
      //   break;
    }
  }

  /* Function for navigating to tab */
  public gotoMainTabs(tabName) {

    this.activeMainTab = tabName;
    if (tabName === 'app-drop' || tabName === 'user-drop') {
      this.showTabDropdown = tabName;
    } else {
      this.showTabDropdown = '';
    }
    if (tabName !== 'app-drop') {
      this.dashboardName = 'Appointment Dashboard';
    }

    switch (tabName) {
      // case 'hedis':
      //   this.router.navigate(['dashboard/internal/hedis']);
      //   break;
      case 'directory':
        this.router.navigate(['dashboard/common/directory']);
        break;
      case 'capitation':
        this.router.navigate(['dashboard/internal/capitation']);
        break;
      case 'insurance':
        this.router.navigate(['dashboard/internal/insurance']);
        break;
      case 'locations':
        document.location.href = 'http://www.araneye.com/locations';
        break;

    }
  }
  /* Function for navigating into user management tabs */
  public gotoSubTabs(tabName) {
    this.activeSubTab = tabName;
    this.showReportsDrop = tabName;
    switch (tabName) {
      case 'int-user-dash':
        break;
      case 'ext-user-dash':
        break;
      case 'user-creation':
        this.showTabDropdown = '';
        this.activeMainTab = 'user-drop';
        this.dashboardName = 'Appointment Dashboard';
        this.router.navigate(['dashboard/internal/user-management']);
        break;
      case 'clinic-management':
        this.showTabDropdown = '';
        this.activeMainTab = 'user-drop';
        this.dashboardName = 'Appointment Dashboard';
        this.router.navigate(['dashboard/internal/clinic-management']);
        break;
      case 'send-notification':
        this.showTabDropdown = '';
        this.activeMainTab = 'user-drop';
        this.dashboardName = 'Appointment Dashboard';
        this.router.navigate(['dashboard/common/notifications/send-notification']);
        break;
      case 'content-management':
        break;
      case 'cust-appoint-dash':
        break;
      case 'appoint-dash':
        this.activeMainTab = 'app-drop';
        this.dashboardName = 'Appointment Dashboard';
        this.showTabDropdown = '';
        this.router.navigate(['dashboard/internal']);
        break;
      case 'prescribe-dash':
        this.showTabDropdown = '';
        this.activeMainTab = 'app-drop';
        this.dashboardName = 'Prescription Refill Dashboard';
        this.router.navigate(['dashboard/internal/prescription-dashboard']);
        break;
      case 'mr-dashboard':
        this.showTabDropdown = '';
        this.activeMainTab = 'app-drop';
        this.dashboardName = 'Medical Records Dashboard';
        this.router.navigate(['dashboard/internal/medical-record']);
        break;
      case 'pending-dash':
        this.showTabDropdown = '';
        this.activeMainTab = 'app-drop';
        this.dashboardName = 'Pending Dashboard';
        this.router.navigate(['dashboard/internal/pending-dashboard']);
        break;
      default:
        break;
    }
  }
  public reportsHideBtn() {
    const userRole = this.common.getUserRole();
    if (userRole) {
      if (userRole.toLowerCase() === 'admin') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  /*
  * Function for permissions  to show send notification option
  * INTERNAL ADMIN - ALWAYS SHOW THE OPTION
  * INTERNAL MANAGER - NOTIFCATION PREVILEGE - VIEW AND EDIT PERMISSIONS
 */
  public internalPermisson() {
    const Permissions = [];
    const userPrivilegeDetails = this.common.getUserPrivilegeDetails();
    const userRole = this.common.getUserRole();
    // userPrivilegeDetails.forEach(preElement => {
    //   preElement.Permissions.forEach(permissionEle => {
    //     Permissions.push(permissionEle.Permission.toLowerCase());
    //   });
    // });
    // if (userPrivilegeDetails && userPrivilegeDetails.Permissions.length > 0) {
    //   userPrivilegeDetails.Permissions.forEach(element => {
    //     Permissions.push(element.Permission.toLowerCase());
    //   });
    // }

    if ((this.userType === 'Internal' && userRole && userRole.toLowerCase() === 'admin')) {
      // this.common.setNotificationPermission(true);
      return true;
    } else if ((this.userType === 'Internal' && userRole && userRole.toLowerCase() === 'manager')) {
      const notificationPermission = this.common.getSendBtnNotificationPermission();
      if (notificationPermission) {
        return true;
      } else {
        return false;
      }
      // userPrivilegeDetails.forEach(preElement => {
      //   if (preElement.PrivilegeName.toLowerCase() === 'notifications' &&
      //   preElement.TempPermission.indexOf('view') >= 0 &&
      //   preElement.TempPermission.indexOf('edit') >= 0) {

      //     // let view = false;
      //     // let edit = false;
      //     // preElement.Permissions.forEach(permissionElement => {
      //     //   if (permissionElement.Permission.toLowerCase() === 'view') {
      //     //     view = true;
      //     //   } else if (permissionElement.Permission.toLowerCase() === 'edit') {
      //     //     edit = true;
      //     //   }
      //     // });
      //     // if (view && edit) {
      //       this.common.setNotificationPermission(true);
      //       // return true;
      //     // }
      //   }
      // });
    } else {
      // this.common.setNotificationPermission(false);
      return false;
    }


    // if ((this.userType === 'Internal' && userRole && userRole.toLowerCase() === 'admin') ||
    //   ((this.userType === 'Internal' && userRole && userRole.toLowerCase() === 'manager') &&
    //     userPrivilegeDetails && userPrivilegeDetails.PrivilegeName &&
    //     userPrivilegeDetails.PrivilegeName.toLowerCase() === 'notifications' &&
    //     Permissions.indexOf('view') >= 0 &&
    //     Permissions.indexOf('edit') >= 0)) {
    //   return true;
    // } else {
    //   return false;
    // }
  }
  public reportUrlCheck() {

    switch (this.router.url.split('/')[4]) {

      case 'user-activity':
        this.activeMainTab = 'user-drop';
        this.dashboardName = 'Appointment Dashboard';
        this.activeSubTab = 'reports';
        this.activeReportsTab = 'user-activity';
        break;
      case 'res-turnaround-time':
        this.activeMainTab = 'user-drop';
        this.dashboardName = 'Appointment Dashboard';
        this.activeSubTab = 'reports';
        this.activeReportsTab = 'res-turnaround-time';
        break;
      case 'appointment-overview':
        this.activeMainTab = 'user-drop';
        this.dashboardName = 'Appointment Dashboard';
        this.activeSubTab = 'reports';
        this.activeReportsTab = 'appointment-overview';
        break;
      case 'user-access-report':
        this.activeMainTab = 'user-drop';
        this.dashboardName = 'Appointment Dashboard';
        this.activeSubTab = 'reports';
        this.activeReportsTab = 'user-access-report';
        break;
      case 'pcp-report':
        this.activeMainTab = 'user-drop';
        this.dashboardName = 'Appointment Dashboard';
        this.activeSubTab = 'reports';
        this.activeReportsTab = 'pcp-report';
        break;
      default:
        break;
    }
  }
  /* Function for checking active tab */
  public checkTabSelection() {
    if (this.activeMainTab === 'initial' || this.activeMainTab !== this.showTabDropdown) {
      this.activeReportsTab = '';
      /* to make active which tab is active on refresh check the url*/
      switch (this.router.url.split('/')[3]) {
        case 'appointment-dashboard':
          this.activeMainTab = 'app-drop';
          this.activeSubTab = 'appoint-dash';
          this.dashboardName = 'Appointment Dashboard';
          break;
        case 'prescription-dashboard':
          this.activeMainTab = 'app-drop';
          this.activeSubTab = 'prescribe-dash';
          this.dashboardName = 'Prescription Refill Dashboard';
          break;
        case 'medical-record':
          this.activeMainTab = 'app-drop';
          this.activeSubTab = 'mr-dashboard';
          this.dashboardName = 'Medical Records Dashboard';
          break;
        case 'pending-dashboard':
          this.activeMainTab = 'app-drop';
          this.activeSubTab = 'pending-dash';
          this.dashboardName = 'Pending Dashboard';
          break;
        case 'user-management':
          this.activeMainTab = 'user-drop';
          this.dashboardName = 'Appointment Dashboard';
          this.activeSubTab = 'user-creation';
          break;
        case 'clinic-management':
          this.activeMainTab = 'user-drop';
          this.dashboardName = 'Appointment Dashboard';
          this.activeSubTab = 'clinic-management';
          break;
        case 'notifications':
          this.activeMainTab = 'user-drop';
          this.dashboardName = 'Appointment Dashboard';
          this.activeSubTab = 'send-notification';
          break;
        case 'reports':
          this.reportUrlCheck();
          break;
        case 'directory':
          this.showTabDropdown = '';
          this.activeMainTab = 'directory';
          this.dashboardName = 'Appointment Dashboard';
          this.router.navigate(['dashboard/common/directory']);
          break;
        case 'insurance':
          this.showTabDropdown = '';
          this.activeMainTab = 'insurance';
          this.dashboardName = 'Appointment Dashboard';
          this.router.navigate(['dashboard/internal/insurance']);
          break;
          case 'capitation':
            this.showTabDropdown = '';
            this.activeMainTab = 'capitation';
            this.dashboardName = 'Appointment Dashboard';
            this.router.navigate(['dashboard/internal/capitation']);
            break;
        // case 'hedis':
        //   this.showTabDropdown = '';
        //   this.activeMainTab = 'hedis';
        //   this.dashboardName = 'Appointment Dashboard';
        //   this.router.navigate(['dashboard/internal/hedis']);
        //   break;
        default:
          break;
      }
    }
  }
}
