import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ApiService,CommonService, ApiUrlConstants, LoaderService } from '../../../utils/imports';
@Component({
  selector: 'app-auto-complete-pcp',
  templateUrl: './auto-complete-pcp.component.html'
})
export class AutoCompletePCPComponent implements OnInit {
  @Output() pcpOutput = new EventEmitter();
  @Input() pcpInput;
  public searchParam;
  public keyword = 'Name';
  public displayField = true;
  public pcpList = [];
  public pcpComplete = [];
  public userType;

  constructor(
    private readonly api: ApiService,
    public readonly loader: LoaderService,
    private readonly common: CommonService
  ) { }

  ngOnInit() {
    if (this.pcpInput) {
      this.searchParam = this.pcpInput.Name;
    } else {
      this.searchParam = '';
    }
    this.getPCPList();
  }

  /* Function for get the list of locations */
  private getPCPList() {
    this.loader.startLoader();
    let url = ApiUrlConstants.URL.pcpListURL;
    url = url.replace('CLINIC_ID', this.common.getUserClinicID());
    if (this.userType === 'External') {
      url = url.replace('IS_EXTERNAL', 'true');
    } else {
      url = url.replace('IS_EXTERNAL', 'false');
    }
    this.api.ExecuteGet(url, (response) => {
      if (response.Data && response.Data.length) {
        this.pcpList = response.Data;
        this.pcpComplete = response.Data;
      }
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
      this.pcpList = [];
    });
  }

  /* Function for triggered when input is changed */
  public pcpListInput() {
    this.pcpList = [];
    this.pcpComplete.forEach(element => {
      if (element.Name.toLowerCase().startsWith(this.searchParam.toLowerCase())) {
        this.pcpList.push(element);
      }
    });
  }

  /** Function for trigger when an item is selected from the list
   * @param event output event
   */
  public selectEvent(event) {
    this.pcpInput.Name = event.Name;
    this.pcpInput.ID = event.ID;
    this.pcpOutput.next(this.pcpInput);
  }

  /* Function for trigger when the panel is closed */
  public closePanel() {
    Array.from(document.getElementsByClassName('is-visible')).forEach((element: HTMLElement) => {
      element.style.display = 'none';
    });
    this.pcpList = this.pcpComplete;
  }

  /* Function for clear the input field */
  public clearPCP() {
    this.searchParam = '';
    this.pcpOutput.next(null);
    this.pcpList = this.pcpComplete;
  }
  public opened() {
    Array.from(document.getElementsByClassName('is-visible')).forEach((element: HTMLElement) => {
      element.style.display = 'block';
    });
  }
}
