import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators,AbstractControl } from '@angular/forms';
import { CommonService, MESSAGE_TEXT, ApiService, ApiUrlConstants, PatternConstants, LoaderService } from '../../../shared/utils/imports';
import { DateValidator } from '../../../shared/validators/date-validator';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-medical-record-popup',
  templateUrl: './medical-record-popup.component.html'
})
export class MedicalRecordPopupComponent implements OnInit {
  @Input() MRRequestInput;
  @Output() MRRequestOutput = new EventEmitter();
  public medicalform: FormGroup;
  public phoneNumberMask = PatternConstants.phoneNumberPattern;
  public dateData = {
    label: 'DOB',
    dateJsValue: null,
    dateValue: { year: null, month: null, day: null },
    id: ''
  };
  public loading = false;
  public medicalRecordTypeList = [];
  public companyLists = [];
  public isCompanyDisplay = 0;
  // public isChenMed = 0;
  public sendingMethod = 'Email';
  public formSubmit = false;
  public recordTypeField = {
    show: false,
    RecordTypeName: 'Select Type Of Record',
    RecordTypeID: null
  };
  public companyField = {
    show: false,
    CompanyName: 'Select Company',
    CompanyId: null
  };
  public toasterData;
  public showToaster = false;
  public dateMask: any[] = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public medicalRecordDate = '';
  public isDateEmpty = true;
  public isEmailAndFaxEmpty=true;
  public invalidDate = false;
  constructor(
    private readonly api: ApiService,
    private readonly datepipe: DatePipe,
    private readonly common: CommonService,
    private readonly formBuilder: FormBuilder,
    public readonly loader: LoaderService
  ) { }

  ngOnInit() {
     this.getmedicalRecordType();
     this.getCompanyList();
    this.createForms();
  }

  /* Function for event emitter from dob filter component */
  public dobFilterEvent(event) {
    if (event === 'clear') {
    }
  }

  /* Function for creating the form elements */
   private createForms() {
    this.medicalform = this.formBuilder.group({
      medemail: [null, [Validators.email]],
      faxNumber : [null, [Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$')]],
      medicalRecordDate: [null, [Validators.required, DateValidator.dateVaidator]]
    });
  }
//,this.customeEmailValidator

//   getError(control:any) : string {
//     if(control.errors.required && control.touched)
//       return "This field is required";
//     else if(control.errors.emailError && control.touched)
//       return 'Please enter valid email address';
//     else return '';
// }

//   customeEmailValidator(control:AbstractControl) {
//     const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,20}$/;
//     const value = control.value;
//     if(!pattern.test(value) && control.touched)
//       return {
//         emailError:true
//       }
//     else return null;
// }


  /* Function for toggle dropdown */
  public toggleDropdown() {
    this.recordTypeField.show = !this.recordTypeField.show;
  }
  public toggleCompanyDropdown() {
    this.companyField.show = !this.companyField.show;
  }

  /* Function for hide the dropdown clicking outside */
  public dropdownClickOutside(event) {
    this.recordTypeField.show = false;
  }
  public dropdownCompanyClickOutside(event) {
    this.companyField.show = false;
  }
  public clearEmailFax(event)
  {
    this.medicalform.controls.medemail.reset();
    this.medicalform.controls.faxNumber.reset();
  }

  /* Function for trigger when selecting any value in dropdown */
  public typeDropdownSelect(item) {
    this.recordTypeField.RecordTypeName = item.RecordTypeName;
    this.recordTypeField.RecordTypeID = item.RecordTypeID;
  }
  public typeCompanyDropdownSelect(item) {
    this.companyField.CompanyId = item.CompanyId;
    this.companyField.CompanyName = item.CompanyName;
  }

  /* Function for clear reason */
  public clearReason() {
    this.recordTypeField.RecordTypeName = 'Select Type Of Record';
    this.recordTypeField.RecordTypeID = null;
    this.recordTypeField.show = false;
  }
  public clearCompany() {
    this.companyField.CompanyName = 'Select Company';
    this.companyField.CompanyId = null;
    this.companyField.show = false;
  }

  /* Function for close confirm dialog */
  public closeConfirm(action) {
    this.MRRequestOutput.next(false);
  }

  /* Function for request click */
  public requestClick() {
    // debugger;
    this.formSubmit = true;
    let reqDate = this.setDateFormat();
    if (reqDate == '') {
      this.isDateEmpty = true;
      return;
    } else {
      this.isDateEmpty = false;
    }
    // this.loading = true;
    this.loader.startLoader();
    const url = ApiUrlConstants.URL.requestMRURL;
    const requestBody = {
      MedicalRecordDocumentTypeID: this.recordTypeField.RecordTypeID,
      MedicalRecordSendingType: this.sendingMethod,
      MedicalRecordEmail: this.medicalform.value.medemail,
      MedicalRecordFAXNumber: this.medicalform.value.faxNumber,
      MedicalRecordDateOfRequest: reqDate,
      MedicalRecordPatientID: this.MRRequestInput.patientID,
      CompanyId: this.companyField.CompanyId,
      User: this.common.getUserID()
    };
    this.api.ExecutePost(url, requestBody, (response) => {
      this.loader.stopLoader();
      if (response.ResponseCode === 200) {
        // this.loading = false;
        this.toasterData = {
          heading: MESSAGE_TEXT.ALERT_SUCCESS_TITLE,
          message: response.ResponseMessage,
          buttonText: MESSAGE_TEXT.ALERT_BUTTON,
          type: 'REQUEST_SUCCESS',
          showToaster: true
        };
        this.showToaster = true;
      }
    }, (error) => {
      this.loader.stopLoader();
      this.toasterData = {
        status: 'FAILURE',
        heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
        message: MESSAGE_TEXT.SERVER_ERROR_MSG,
        buttonText: MESSAGE_TEXT.ALERT_BUTTON,
        type: 'REQUEST_FAILED',
        showToaster: true
      };
      this.showToaster = true;
    });
  }

  /* Function for api medical record type */
  public getmedicalRecordType() {
    this.loading = true;
    const url = ApiUrlConstants.URL.mrRecordTypeURL;
    this.medicalRecordTypeList = [];
    this.api.ExecuteGet(url, (response) => {
      if (response.Data && response.Data.length) {
        this.loading = false;
        this.medicalRecordTypeList = response.Data;
      }
    }, (error) => {

    });
  }

    /* Function for listing company api */
    public getCompanyList() {
      this.loading = true;
      const url = ApiUrlConstants.URL.companiesListURL;
      this.companyLists = [];
      this.api.ExecuteGet(url, (response) => {
        if (response.Data && response.Data.length) {
          this.loading = false;
          this.companyLists = response.Data;
          this.isCompanyDisplay=response.Data[0].IsCompanyDisplay;
          //this.isChenMed= parseInt(JSON.parse(sessionStorage.getItem('IsChenMedClinic')));
          this.setCompanyValidators();
        }
      }, (error) => {

      });
    }

    /* Validation for listing company only for chen med users and internal users */
    private setCompanyValidators() {
      const companyControl = this.medicalform.get('company');
      // if(this.isChenMed === 1) {
        companyControl.setValidators(Validators.required);
    // } else {
    //   companyControl.clearValidators();
    // }
    companyControl.updateValueAndValidity();
    }

  /* Function for close the toaster */
  public closeToaster(event) {
    this.showToaster = event.showToaster;
    if (event.type === 'REQUEST_SUCCESS') {
      this.closeConfirm('send');
    }
  }
/* function for check all fields valid */
  public disableReqButton() {
    var requestDate = this.medicalform.value.medicalRecordDate;
    if (requestDate == '') {
      this.isDateEmpty = true;
    } else {
      this.isDateEmpty = false;
    }
    if(this.sendingMethod == 'Email' && (this.medicalform.value.medemail == null || this.medicalform.value.medemail ==''))
    {
      this.isEmailAndFaxEmpty=true;
    }
    else if(this.sendingMethod == 'Fax' && (this.medicalform.value.faxNumber == null ||this.medicalform.value.faxNumber ==''))
    {
      this.isEmailAndFaxEmpty=true;
    }
    else{
      this.isEmailAndFaxEmpty=false;
    }
    if ((this.companyField.CompanyId < 1 || this.companyField.CompanyId === null) || this.recordTypeField.RecordTypeID < 1 || this.recordTypeField.RecordTypeID === null || this.invalidDate || this.isDateEmpty || this.isEmailAndFaxEmpty || !this.medicalform.valid ) {
      return true;
    }
    else {
      return false;
    }
  }

  public dateChange() {
    this.isDateEmpty = false;
    if (!this.medicalform.get('medicalRecordDate').hasError('dateVaidator')) {
      const dateValue = this.medicalform.value.medicalRecordDate;
      const formattedDate = dateValue.split('/')[2] + '-' + dateValue.split('/')[0] + '-' + dateValue.split('/')[1];
      this.invalidDate= this.checkDate(new Date(formattedDate));
    }
  }

  public setDateFormat() {
    const dateValue = this.medicalform.value.medicalRecordDate;
    const formattedDate = dateValue.split('/')[2] + '-' + dateValue.split('/')[0] + '-' + dateValue.split('/')[1];
    if (this.common.checkForAPIDate(formattedDate)) {
      return formattedDate;
    } else {
      return '';
    }
  }
  public checkDate(date1) {
    const d1 = new Date(date1);
    if (d1.getFullYear() < 1753) {
      return true;
    } else {
      return false;
    }
  }
}
