import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { KeyConstants } from '../constants/constant-keys';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public externalHeaderSource: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public externalHeaderCheck = this.externalHeaderSource.asObservable();

  public internalHeaderSource: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public internalHeaderCheck = this.internalHeaderSource.asObservable();

  public internalDashboardTabSource: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public internalDashboardTabCheck = this.internalDashboardTabSource.asObservable();

  public externalDashboardTabSource: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public externalDashboardTabCheck = this.externalDashboardTabSource.asObservable();

  public timeoutSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public timeoutCheck = this.timeoutSource.asObservable();

  public userTypeSource: BehaviorSubject<string> = new BehaviorSubject('');
  public userTypeCheck = this.userTypeSource.asObservable();

  public callNotificationSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public callNotificationCheck = this.callNotificationSource.asObservable();

  public signalrRefreshSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public signalrRefreshCheck = this.signalrRefreshSource.asObservable();

  public signalTotalCountSource: BehaviorSubject<number>= new BehaviorSubject(0);
  public signalTotalCountCheck = this.signalTotalCountSource.asObservable();

  public signalShowImageSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public signalShowImageCheck = this.signalShowImageSource.asObservable();

  public signalNotificationBarSource:BehaviorSubject<boolean> = new BehaviorSubject(false);
  public signalNotificationBarCheck = this.signalNotificationBarSource.asObservable();

  public transportindex;

  constructor() { }

  /* get user details from sessionStorage */
  public getUserDetails() {
    if (sessionStorage.getItem('userDetails')) {
      return (JSON.parse(sessionStorage.getItem('userDetails')));
    } else {
      return null;
    }
  }

  /* to get the type of user logged */
  public getUserType() {
    if (sessionStorage.getItem('userDetails')) {
      return JSON.parse(sessionStorage.getItem('userDetails')).UserType;
    } else {
      return null;
    }
  }

  /* to get the type of user logged */
  public getUserDeptID() {
    if (sessionStorage.getItem('userDetails')) {
      return JSON.parse(sessionStorage.getItem('userDetails')).DepartmentID;
    } else {
      return null;
    }
  }

  /* to get the type of clinic logged */
  public getClinicType() {
    if (sessionStorage.getItem('userDetails')) {
      return JSON.parse(sessionStorage.getItem('userDetails')).IsOPHClinic;
    } else {
      return null;
    }
  }

  /* to get the type of user logged */
  public getUserID() {
    if (sessionStorage.getItem('userDetails')) {
      return JSON.parse(sessionStorage.getItem('userDetails')).UserId;
    } else {
      return null;
    }
  }
  /* to get the ClinicID */

  public getUserClinicID() {
    if (sessionStorage.getItem('userDetails')) {
      return JSON.parse(sessionStorage.getItem('userDetails')).ClinicID;
    } else {
      return null;
    }
  }

  /* to get the PrivilegeDetails */

  public getUserPrivilegeDetails() {
    if (sessionStorage.getItem('sendNotificationPermissionDetails')) {
      return JSON.parse(sessionStorage.getItem('sendNotificationPermissionDetails'));
    } else {
      return null;
    }
  }
  public setSendBtnNotificationPermission(userPrivilegeDetails) {
    sessionStorage.setItem('sendNotificationPermission', 'false');
    userPrivilegeDetails.forEach(preElement => {
      if (preElement.PrivilegeName.toLowerCase() === 'notifications' &&
        preElement.TempPermission.indexOf('view') >= 0 &&
        preElement.TempPermission.indexOf('edit') >= 0) {
        sessionStorage.setItem('sendNotificationPermission', 'true');
      }
    });
  }
  public getSendBtnNotificationPermission() {
    if (sessionStorage.getItem('sendNotificationPermission')) {
      return sessionStorage.getItem('sendNotificationPermission') === 'true' ? true : false;
    }
  }
  public setViewReportsPermission(userPrivilegeDetails) {
    sessionStorage.setItem('viewReportsPermission', 'false');
    userPrivilegeDetails.forEach(preElement => {
      if (preElement.PrivilegeName.toLowerCase() === 'reports' &&
        preElement.TempPermission.indexOf('view') >= 0) {
        sessionStorage.setItem('viewReportsPermission', 'true');
      }
    });
  }
  public getViewReportsPermission() {
    if (sessionStorage.getItem('viewReportsPermission')) {
      return sessionStorage.getItem('viewReportsPermission') === 'true' ? true : false;
    }
  }
  public setEditReportsPermission(userPrivilegeDetails) {
    sessionStorage.setItem('editReportsPermission', 'false');
    userPrivilegeDetails.forEach(preElement => {
      if (preElement.PrivilegeName.toLowerCase() === 'reports' &&
        preElement.TempPermission.indexOf('edit') >= 0) {
        sessionStorage.setItem('editReportsPermission', 'true');
      }
    });
  }
  public getEditReportsPermission() {
    if (sessionStorage.getItem('editReportsPermission')) {
      return sessionStorage.getItem('editReportsPermission') === 'true' ? true : false;
    }
  }
  /* to get the User Role */

  public getUserRole() {
    if (sessionStorage.getItem('userDetails')) {
      return JSON.parse(sessionStorage.getItem('userDetails')).UserRole;
    } else {
      return null;
    }
  }

  /* get user details from sessionStorage */
  public getUserToken() {
    if (sessionStorage.getItem('userToken')) {
      return (JSON.parse(sessionStorage.getItem('userToken')));
    } else {
      return null;
    }
  }

  /* get refresh token from sessionStorage */
  public getRefreshToken() {
    if (sessionStorage.getItem('refreshToken')) {
      return (JSON.parse(sessionStorage.getItem('refreshToken')));
    } else {
      return null;
    }
  }

  /* get user name from sessionStorage */
  public getUsername() {
    if (sessionStorage.getItem('userDetails')) {
      return (this.decryptUsingAES(JSON.parse(sessionStorage.getItem('userDetails')).UserFullName));
    } else {
      return '';
    }
  }

  /* function for the user to logout and clear all data */
  public userLogout() {
    sessionStorage.clear();
  }

  /* function for AES encrypted parameter */
  public encryptUsingAES(valueToEncrypt) {
    if (valueToEncrypt) {
      const encKey = KeyConstants.encryptionKey;
      return CryptoJS.AES.encrypt(valueToEncrypt, encKey).toString();
    } else {
      return null;
    }
  }

  /* function for AES decrypted parameter */
  public decryptUsingAES(valueToDecrypt) {
    if (valueToDecrypt) {
      const encKey = KeyConstants.encryptionKey;
      return CryptoJS.AES.decrypt(valueToDecrypt.trim(), encKey).toString(CryptoJS.enc.Utf8);
    } else {
      return null;
    }
  }

  /* function for opening new browser tab with link */
  public openNewTab(browserUrl) {
    window.open(browserUrl, '_blank');
  }

  /* sort table columns */
  public sortColumn(arrayToSort, columnToSort, sortOrder) {
    let columnListflag = 1;
    let keepGoing = true;
    const sortColumnValues = [];
    arrayToSort.forEach(element => {
      sortColumnValues.push(element[columnToSort]);
    });
    const firstSortColumnValue = sortColumnValues[0];
    sortColumnValues.forEach(element => {
      if (keepGoing) {
        /* Checking data in the list are same in case of non-number lists */
        if (element !== firstSortColumnValue) {
          columnListflag = 0;
          keepGoing = false;
        } else {
          columnListflag = 1;
        }
      }
    });
    if (columnListflag === 1) {
      return arrayToSort;
    } else {
      arrayToSort.forEach(element => {
        if (element[columnToSort] === null) {
          element[columnToSort] = '';
        }
      });
      if (arrayToSort != null) {
        arrayToSort.sort((a, b) => {
          return a[columnToSort].localeCompare(b[columnToSort]);
        });
        if (sortOrder === 'ASC') {
          return arrayToSort;
        } else {
          return arrayToSort.reverse();
        }
      }
    }
  }

  /* sort table columns number */
  public sortNumber(arrayToSort, columnToSort, sortOrder) {
    let columnListflag = 1;
    let keepGoing = true;
    const sortColumnValues = [];
    arrayToSort.forEach(element => {
      sortColumnValues.push(element[columnToSort]);
    });
    const firstSortColumnValue = sortColumnValues[0];
    sortColumnValues.forEach(element => {
      /* Checking data in the list are same in case of number list */
      if (keepGoing) {
        if (element !== firstSortColumnValue) {
          columnListflag = 0;
          keepGoing = false;
        } else {
          columnListflag = 1;
        }
      }
    });
    if (columnListflag === 1) {
      return arrayToSort;
    } else {
      const sortedRecords = arrayToSort;
      if (sortedRecords != null) {
        sortedRecords.sort((a, b) => {
          return a[columnToSort] - b[columnToSort];
        });
        if (sortOrder === 'ASC') {
          return sortedRecords;
        } else {
          return sortedRecords.reverse();
        }
      }
    }
  }
  /* fn for encrypting data for security reasons */
  public encrptData(valueToEncrypt) {
    const encKey = 'ABHI1631523UYNAM';
    const key = CryptoJS.enc.Utf8.parse(encKey);
    const iv = CryptoJS.enc.Utf8.parse(encKey);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(valueToEncrypt.toString()), key,
      {
        keySize: 128 / 8,
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    return encrypted.toString();
  }

  /* fn for decrypting data for security reasons */
  public decryptData(encryptedValue) {
    const encKey = 'ABHI1631523UYNAM';
    const key = CryptoJS.enc.Utf8.parse(encKey);
    const iv = CryptoJS.enc.Utf8.parse(encKey);
    const decrypted = CryptoJS.AES.decrypt(encryptedValue, key, {
      keySize: 128 / 8,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  /* show and hide the external tabs section */
  public setExternalHeader(actionFlag) {
    this.externalHeaderSource.next(actionFlag);
    sessionStorage.setItem('showExtTab', JSON.stringify(actionFlag));
  }

  /* show and hide the external tabs section */
  public setInternalHeader(actionFlag) {
    this.internalHeaderSource.next(actionFlag);
    sessionStorage.setItem('showIntTab', JSON.stringify(actionFlag));
  }

  /* highlight the appointment tab */
  public setInternalDashboardTab(actionFlag) {
    this.internalDashboardTabSource.next(actionFlag);
    sessionStorage.setItem('showIntDashboardTab', JSON.stringify(actionFlag));
  }
  /* highlight the todays tab */
  public setExternalDashboardTab(actionFlag) {
    this.externalDashboardTabSource.next(actionFlag);
    sessionStorage.setItem('showExtDashboardTab', JSON.stringify(actionFlag));
  }
  public setTimeoutValue(value) {
    this.timeoutSource.next(value);
  }

  public setUserType(value) {
    this.userTypeSource.next(value);
  }
  public setsignalrRefreshValue(value) {
    this.signalrRefreshSource.next(value);
  }

  public setCallValue(value) {
    this.callNotificationSource.next(value);
  }
  public downloadPDF(base64, fileName) {
    const downloadLink = document.createElement('a');
    downloadLink.href = `data:application/pdf;base64,${base64}`;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  public downloadFile(response, fileName) {
    const newBlob = new Blob([response], { type: 'application/pdf' });
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  }
  public downloadFileTest(response, fileName, fileType) {
    console.log(fileType);
    const newBlob = new Blob([response], { type: fileType });
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  }
  /**
   * Function to fecth filename from API response header
   * @param headerFileName is the value in header response
   * @returns returns the file name after removing unwanted params
   */
  public fetchFileNameFromHeader(headerFileName) {
    if (headerFileName) {
      const filename = headerFileName.split('attachment; filename=')[1];
      const prefixQuote = filename.replace('"', '');
      const originalFileName = prefixQuote.replace('"', '');
      return originalFileName;
    } else {
      return '';
    }
  }
  /**
   * function for checking whether the given string includes any special character or not
   * @param fieldValue = value to be tested
   * @returns true if it includes special character
   * @returns returns false if it doesnot includes special character
   */
  public checkForAPIDate(fieldateValuedValue) {
    const format = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$');
    if (fieldateValuedValue && format.test(fieldateValuedValue)) {
      return true;
    } else {
      return false;
    }
  }
}
