import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService, ApiUrlConstants, LoaderService } from '../../../utils/imports';

@Component({
  selector: 'app-company-filter-external',
  templateUrl: './company-filter-external.component.html'
})
export class CompanyFilterExternalComponent implements OnInit {
  @Input() dropdownFilterExternalCompany;
  @Output() companyFilterOutput = new EventEmitter();
  public showList;
  public companyList = [];
  constructor(
    private readonly api: ApiService,
    public readonly loader: LoaderService
  ) {
    this.getCompanyList();
  }
  ngOnInit() {
    this.showList = this.dropdownFilterExternalCompany.show;
  }
  /* fn trigeered when selecting any value in dropdown */
  public clearFilter() {
    this.dropdownFilterExternalCompany.CompanyName = 'ALL';
    this.dropdownFilterExternalCompany.CompanyId = null;    
    this.companyFilterOutput.next('clear');
  }
  /* fn trigeered when selecting any value in dropdown */
  public dropdownSelect(item) {
    this.dropdownFilterExternalCompany.CompanyName = item.CompanyName;
    this.dropdownFilterExternalCompany.CompanyId = item.CompanyId;
    this.companyFilterOutput.next(item);
  }
  /* hide the dropdown clicking outside */
  public dropdownClickOutside(event) {
    this.dropdownFilterExternalCompany.show = false;
  }
  /* get the list of companies */
  private getCompanyList() {
    this.loader.startLoader();
    const url = ApiUrlConstants.URL.companiesListURL;
    this.companyList = [];
    this.api.ExecuteGet(url, (response) => {
      if (response.Data && response.Data.length) {
        this.companyList = response.Data;
      }
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
    });
  }

}
