import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, LoaderService, ApiUrlConstants, MESSAGE_TEXT, ArrayConstants, CommonService } from '../../../../shared/utils/imports';
@Component({
  selector: 'app-appointment-table',
  templateUrl: './appointment-table.component.html'
})
export class AppointmentTableComponent implements OnInit {
  @Input() tableFilter;
  public appointmentsList = [];
  public toasterData;
  public showToaster = false;
  public phoneNumberPop = false;
  public actionPopup = false;
  public actionData;
  public AMRecords = 0;
  public PMRecords = 0;
  public fromdate = new Date();
  public todate = new Date();
  public surgeryTimeMsg=MESSAGE_TEXT.SURGERY_TIME_TBA_HOVER_MSG;
  public phoneNumberData = {
    item: null,
    action: null,
    phoneNumber: null
  };
  public itemsPerList = ArrayConstants.itemsPerPageArray;
  public itemsPerPage = 10;
  public itemsPer = {
    count: 0,
    totalItems: 0
  };
  /* sorting begin */
  public sortID = '';
  public sortName = '';
  public sortAppId = '';
  public sortDOB = '';
  public sortDate = '';
  public sortDatesortDate = '';
  public sortTime = '';
  public sortLocation = '';
  public sortApptType = '';
  /* sorting end */

  public currentPage = 1;
  public userType;
  public transportForIndex;
  public today = new Date().toISOString().split('T')[0];
  public headerIntermediate = false;
  public headerCheckbox = false;
  public showBulkCancel = false;
  public showBulkReschedule = false;
  public selectedNumber = 0;
  public showBulkConfirm = false;
  public bulkConfirmData;
  public tempTransportValue = '';
  constructor(
    private readonly api: ApiService,
    private readonly common: CommonService,
    private readonly router: Router,
    public readonly loader: LoaderService
  ) { }

  ngOnInit() {
    this.checkUserType();
  }

  /* Function for check the type of user logged */
  public checkUserType() {
    this.userType = this.common.getUserType();
    this.getAppointmentsList();
  }

  /* Function for trigger for page change event */
  public pageChange(newPage: number) {
    this.currentPage = newPage;
    this.tableFilter.requestData.PageNumber = newPage;
    this.getAppointmentsList();
  }

  /* Function for trigger when items per change value is changed */
  public itemsPerPageChange() {
    this.tableFilter.requestData.PageSize = this.itemsPerPage;
    this.tableFilter.requestData.PageNumber = 1;
    this.getAppointmentsList();
  }

  /* Function for show show bulk confirmation */
  public showBulkConfirmation(actionType) {
    if (actionType === 'Reschedule') {
      this.bulkConfirmData = {
        actionType,
        action: null,
        heading: 'CONFIRMATION',
        message: 'Are you sure you want to reschedule all the selected appointments?',
        rightButton: 'Yes, reschedule'
      };
    } else {
      this.bulkConfirmData = {
        actionType,
        action: null,
        heading: 'CONFIRMATION',
        message: 'Are you sure you want to cancel all the selected appointments?',
        rightButton: 'Yes, cancel'
      };
    }
    this.showBulkConfirm = true;
  }

  /* Function for bulk cancel reschedule */
  public bulkCancelReschedule(actionType) {
    const sampleArray = [];
    this.appointmentsList.forEach(element => {
      if (element.isSelected) {
        sampleArray.push(element);
      }
    });
    this.showActionPop(sampleArray, actionType, 'bulk');
  }

  /* Function for action pop */
  public showActionPop(item, actionType, selectionType) {
    if (this.userType === 'External') {
      if (actionType === 'PendingCancel') {
        this.actionData = {
          message: 'Do you want to cancel the appointment request?',
          leftButton: 'Reschedule',
          rightButton: 'Cancel',
          actionButton: 'Send',
          showReasonsDropdown: true,
          isReschedule: 'Cancel',
          showReasonText: true,
          showRadioButtons: false,
          page: 'ext-cancel-reschedule',
          action: null,
          selectedItem: item,
          OutputData: null
        };
        this.actionPopup = true;
      } else if (this.checkForCancelInBulk(item)) {
        this.showPopupForCancel();
      } else {
        if (this.checkForPendingInBulk(item) && actionType === 'Reschedule') {
          this.showPopupForPending();
        } else {
          if (selectionType === 'individual') {
            this.actionData = {
              message: 'Do you want to cancel or reschedule the appointment ?',
              leftButton: 'Reschedule',
              rightButton: 'Cancel',
              actionButton: 'Send',
              showReasonsDropdown: true,
              isReschedule: actionType,
              showReasonText: true,
              showRadioButtons: true,
              page: 'ext-cancel-reschedule',
              action: null,
              selectedItem: item,
              OutputData: null
            };
            this.actionPopup = true;
          } else {
            if (actionType === 'Cancel') {
              this.actionData = {
                message: 'Do you want to cancel the appointment ?',
                leftButton: 'Reschedule',
                rightButton: 'Cancel',
                actionButton: 'Send',
                showReasonsDropdown: true,
                isReschedule: actionType,
                showReasonText: true,
                showRadioButtons: false,
                page: 'ext-cancel-reschedule',
                action: null,
                selectedItem: item,
                OutputData: null
              };
              this.actionPopup = true;
            } else {
              this.actionData = {
                message: 'Do you want to reschedule the appointment ?',
                leftButton: 'Reschedule',
                rightButton: 'Cancel',
                actionButton: 'Send',
                showReasonsDropdown: true,
                isReschedule: 'Reschedule',
                showReasonText: true,
                showRadioButtons: false,
                page: 'int-reschedule',
                action: null,
                selectedItem: item,
                OutputData: null
              };
              this.actionPopup = true;
            }
          }
        }
      }
    } else if (this.userType === 'Internal') {
      if (this.checkForCancelInBulk(item)) {
        this.showPopupForCancel();
      } else {
        this.actionData = {
          message: 'Do you want to reschedule the appointment ?',
          leftButton: 'Reschedule',
          rightButton: 'Cancel',
          actionButton: 'Send',
          showReasonsDropdown: true,
          isReschedule: 'Reschedule',
          showReasonText: true,
          showRadioButtons: false,
          page: 'int-reschedule',
          action: null,
          selectedItem: item,
          OutputData: null
        };
        this.actionPopup = true;
      }

    }
  }
  /* Function for checking whether any pending item is selected in bulk */
  public checkForPendingInBulk(item) {
    let isPendingSelected = false;
    let loopCount = 0;
    for (loopCount = 0; loopCount < item.length; loopCount++) {
      if (item[loopCount].Status.toLowerCase() === 'pending') {
        isPendingSelected = true;
      }
    }
    if (loopCount === item.length) {
      return isPendingSelected;
    }
  }
  /* Function for checking whether any cancel item is selected in bulk */
  public checkForCancelInBulk(item) {
    let isCancelSelected = false;
    let loopCount = 0;
    for (loopCount = 0; loopCount < item.length; loopCount++) {
      if (item[loopCount].Status.toLowerCase() === 'cancel') {
        isCancelSelected = true;
      }
    }
    if (loopCount === item.length) {
      return isCancelSelected;
    }
  }
  /* Function for showing popup for pending status item while trying to reschedule in bulk action */
  public showPopupForPending() {
    this.actionPopup = false;
    this.toasterData = {
      heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
      message: 'Cannot allow reschedule for pending status appointment.',
      buttonText: MESSAGE_TEXT.ALERT_BUTTON,
      type: 'ERROR_BULK_PENDING',
      showToaster: true
    };
    this.showToaster = true;
  }
  /* Function for showing popup for cancel status item while trying to do action in bulk action */
  public showPopupForCancel() {
    this.toasterData = {
      heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
      message: 'Cannot allow any actions for cancel status appointment.',
      buttonText: MESSAGE_TEXT.ALERT_BUTTON,
      type: 'ERROR_BULK_CANCEL',
      showToaster: true
    };
    this.showToaster = true;
  }
  /* Function for event function for actIon pop */
  public actionPopupEvent(event) {
    this.actionPopup = false;
    if (event.action === 'send') {
      this.cancelRescheduleAPI(event);
    }
  }
  public cancelRescheduleAPI(event) {
    this.loader.startLoader();
    const url = ApiUrlConstants.URL.cancelRescheduleURL;
    const requestBody = [];
    event.selectedItem.forEach(element => {
      requestBody.push(
        {
          AppointmentKey: element.AppointmentKey,
          Reason: event.OutputData.ReasonKey,
          OtherReasons: event.OutputData.OtherReason,
          IsReschedule: (event.page === 'ext-cancel-reschedule' && event.OutputData.actionType === 'Reschedule') ? true :
            (event.page === 'ext-cancel-reschedule' && event.OutputData.actionType === 'Cancel') ? false : true
        }
      );
    });
    this.api.ExecutePost(url, requestBody, (response) => {
      if (response.ResponseCode === 200) {
        this.loader.stopLoader();
        this.toasterData = {
          heading: MESSAGE_TEXT.ALERT_SUCCESS_TITLE,
          message: response.ResponseMessage,
          buttonText: MESSAGE_TEXT.ALERT_BUTTON,
          type: 'CANCEL_RESCHE_SUCCESS',
          showToaster: true
        };
        this.showToaster = true;
      }
    }, (error) => {
      this.loader.stopLoader();
      this.apiFailure(error);
    });
  }

  /* Function for transport api */
  public transportAction(item, transportNumber) {
    this.loader.startLoader();
    const url = ApiUrlConstants.URL.transportChangeURL;
    const requestBody = {
      AppointmentKey: item.AppointmentKey,
      Transport: item.Transport,
      TransportNumber: transportNumber,
      User: this.common.getUserID(),
      Concurrency: item.Concurrency
    };
    this.api.ExecutePut(url, requestBody, (response) => {
      if (response.ResponseCode === 200) {
        this.tempTransportValue = '';
        this.loader.stopLoader();
        this.toasterData = {
          heading: MESSAGE_TEXT.ALERT_SUCCESS_TITLE,
          message: response.ResponseMessage,
          buttonText: MESSAGE_TEXT.ALERT_BUTTON,
          type: 'TRANSPORT_SUCCESS',
          showToaster: true
        };
        this.showToaster = true;
      }
    }, (error) => {
      this.loader.stopLoader();
      item.Transport = this.tempTransportValue;
      this.apiFailure(error);
    });
  }

  /* Function for get the list of locations */
  public getAppointmentsList() {
    this.initializeSortIcons();
    this.loader.startLoader();
    if (this.tableFilter && this.tableFilter.requestData && this.tableFilter.requestData.PageSize) {
      this.itemsPerPage = this.tableFilter.requestData.PageSize;
    } else {
      this.itemsPerPage = 10;
    }
    this.currentPage = this.tableFilter.requestData.PageNumber;
    this.headerCheckbox = false;
    const url = ApiUrlConstants.URL.appointmentListURL;
    const requestBody = this.tableFilter.requestData;
    this.fromdate = this.tableFilter.requestData.FromDate;
    this.todate = this.tableFilter.requestData.ToDate;
    this.appointmentsList = [];
    this.api.ExecutePost(url, requestBody, (response) => {
      if (response.Data && response.Data.length) {
        this.appointmentsList = response.Data;
        this.AMRecords = this.appointmentsList[0].AMRecords;
        this.PMRecords = this.appointmentsList[0].PMRecords;
        this.appointmentsList.forEach(element => {
          element.PatientName = this.common.decryptUsingAES(element.PatientName);
          element.AppointmentType = this.common.decryptUsingAES(element.AppointmentType);
          element.PrimaryPhysician = this.common.decryptUsingAES(element.PrimaryPhysician);
          element.TransportNumber = this.common.decryptUsingAES(element.TransportNumber);
          // element.AppointmentTime = element.AppointmentTime.trim();
        });
        this.itemsPer.totalItems = response.Data[0].TotalRecords;
        this.itemsPer.totalItems <= this.appointmentsList.length ?
          this.itemsPer.count = this.itemsPer.totalItems :
          this.itemsPer.count = this.appointmentsList.length;

        this.uncheckAllBoxes();
        // this.checkAMPMvalues();
      }
      this.loader.stopLoader();
    }, (error) => {
      this.loader.stopLoader();
      this.itemsPer.totalItems = 0;
      this.AMRecords = 0;
      this.PMRecords = 0;
      this.apiFailure(error);
    });
  }

  /* Function for sorting the table column */
  public sortColumn(columnToSort, sortOrder) {
    switch (columnToSort) {
      case 'PatientName':
        this.sortName = sortOrder;
        this.sortDOB = '';
        this.sortDate = '';
        this.sortTime = '';
        this.sortID = '';
        this.sortLocation = '';
        this.sortApptType = '';
        break;
      case 'DOB':
        this.sortDOB = sortOrder;
        this.sortName = '';
        this.sortDate = '';
        this.sortTime = '';
        this.sortID = '';
        this.sortLocation = '';
        this.sortApptType = '';
        break;
      case 'AppointmentDate':
        this.sortDate = sortOrder;
        this.sortName = '';
        this.sortDOB = '';
        this.sortTime = '';
        this.sortID = '';
        this.sortLocation = '';
        this.sortApptType = '';
        break;
      case 'AppointmentUTCTime':
        this.sortTime = sortOrder;
        this.sortName = '';
        this.sortDOB = '';
        this.sortDate = '';
        this.sortID = '';
        this.sortLocation = '';
        this.sortApptType = '';
        break;
      case 'AppointmentID':
        this.sortID = sortOrder;
        this.sortName = '';
        this.sortDOB = '';
        this.sortDate = '';
        this.sortTime = '';
        this.sortLocation = '';
        this.sortApptType = '';
        break;
        case 'Location':
          this.sortID = '';
          this.sortName = '';
          this.sortDOB = '';
          this.sortDate = '';
          this.sortTime = '';
          this.sortLocation = sortOrder;
          this.sortApptType = '';
          break;
          case 'AppointmentType':
            this.sortID = '';
            this.sortName = '';
            this.sortDOB = '';
            this.sortDate = '';
            this.sortTime = '';
            this.sortLocation = '';
            this.sortApptType = sortOrder;
            break;
    }
    this.appointmentsList = this.common.sortColumn(this.appointmentsList, columnToSort, sortOrder);
  }

  /* Function for sort number column */
  public sortNumber(columnToSort, sortOrder) {
    switch (columnToSort) {
      case 'AppointmentID':
        this.sortID = sortOrder;
        this.sortName = '';
        this.sortDOB = '';
        this.sortDate = '';
        this.sortTime = '';
        this.sortLocation = '';
        this.sortApptType = '';
        break;
    }
    this.appointmentsList = this.common.sortNumber(this.appointmentsList, columnToSort, sortOrder);
  }

  /* Function for initialize the sort icons */
  public initializeSortIcons() {
    this.sortName = '';
    this.sortDOB = '';
    this.sortDate = '';
    this.sortTime = '';
    this.sortID = '';
    this.sortLocation = '';
    this.sortApptType = '';
  }

  /* Function for adding the checkbox as unchecked for each user */
  private uncheckAllBoxes() {
    this.appointmentsList.forEach(element => {
      element.isSelected = false;
    });
    this.hideBulkButtons();
  }

  /* Function for adding the checkbox as checked for each user */
  private checkAllBoxes() {
    this.appointmentsList.forEach(element => {
      element.isSelected = true;
    });
    this.showBulkButtons();
  }

  /* Function for trigger while clicking on the checkbox */
  public toggleBulkButton() {
    let checkBoxSelect = false;
    this.appointmentsList.forEach(element => {
      if (element.isSelected) {
        checkBoxSelect = true;
      }
    });
    checkBoxSelect ? this.showBulkButtons() : this.hideBulkButtons();
    this.checkAllBoxCondition();
    this.showHeaderIntermediate();
  }

  /* Function for displaying intermediate icon on the header checkbox */
  public showHeaderIntermediate() {
    let checkBoxSelect = false;
    this.appointmentsList.forEach(element => {
      if (element.isSelected) {
        checkBoxSelect = true;
      }
    });
    checkBoxSelect && !this.headerCheckbox ? this.headerIntermediate = true : this.headerIntermediate = false;
  }


  /* Function for show bulk buttons */
  public showBulkButtons() {
    let sampleBulkReschedule = false;
    this.appointmentsList.forEach(element => {
      if (element.isSelected && (element.Status.toLowerCase() !== 'pending' && element.Status.toLowerCase() !== 'cancel')) {
        sampleBulkReschedule = true;
      }
    });
    sampleBulkReschedule ? this.showBulkReschedule = true : this.showBulkReschedule = false;
    let sampleBulkCancel = false;
    this.appointmentsList.forEach(element => {
      if (element.isSelected && element.Status.toLowerCase() !== 'cancel') {
        sampleBulkCancel = true;
      }
    });
    sampleBulkCancel ? this.showBulkCancel = true : this.showBulkCancel = false;
    const selectedAppointmentsArray = [];
    this.appointmentsList.forEach(element => {
      if (element.isSelected) {
        selectedAppointmentsArray.push(element);
      }
    });
    this.selectedNumber = selectedAppointmentsArray.length;
  }

  /* Function for hide bulk buttons */
  public hideBulkButtons() {
    this.showBulkCancel = false;
    this.showBulkReschedule = false;
  }

  /* Function for trigger while clicking on the checkbox */
  public checkAllBoxCondition() {
    let allChecked = true;
    this.appointmentsList.forEach(element => {
      if (!element.isSelected) {
        allChecked = false;
      }
    });
    allChecked ? this.headerCheckbox = true : this.headerCheckbox = false;
  }

  /* Function for header checkbox click */
  public headerCheckBoxClicked() {
    if (this.headerIntermediate) {
      this.headerCheckbox = false;
      this.headerIntermediate = false;
      this.uncheckAllBoxes();
    } else {
      let allChecked = true;
      this.appointmentsList.forEach(element => {
        if (!element.isSelected) {
          allChecked = false;
        }
      });
      !allChecked ? this.headerCheckbox = true : this.headerCheckbox = false;
      this.headerCheckbox ? this.checkAllBoxes() : this.uncheckAllBoxes();
    }
  }

  /* Function for phone number popup */
  public showPhoneNumberPop(item, index) {
    this.tempTransportValue = item.Transport;
    this.common.transportindex = index;
    if (item.Transport === 'true') {
      this.transportForIndex = index;
      this.phoneNumberData.item = item;
      this.phoneNumberPop = true;
    } else {
      this.transportForIndex = null;
      this.phoneNumberPop = false;
      this.transportAction(item, null);
    }
  }

  /* Function for event function for phone number popup */
  public phonePopupEvent(event) {
    this.phoneNumberPop = false;
    if (event.action === 'send') {
      this.transportAction(event.item, event.phoneNumber);
    }
  }

  /* Function for handling the api errors */
  public apiFailure(error) {
    this.toasterData = null;
    if (error.error && (error.error.ResponseCode === 400 || error.error.ResponseCode === 403)) {
      this.toasterData = {
        heading: MESSAGE_TEXT.ALERT_FAIL_TITLE,
        message: error.error.ResponseMessage,
        buttonText: MESSAGE_TEXT.ALERT_BUTTON,
        type: 'API_FAILED',
        showToaster: true
      };
    } else if (error.error.ResponseCode !== 404) {
      this.toasterData = {
        heading: MESSAGE_TEXT.SERVER_ERROR_TITLE,
        message: error.error.ResponseMessage,
        buttonText: MESSAGE_TEXT.ALERT_BUTTON,
        type: 'API_FAILED',
        showToaster: true
      };
    }
    this.showToaster = true;
  }

  /* Function for close the toaster */
  public closeToaster(event) {
    this.showToaster = event.showToaster;
    if (event.type === 'CANCEL_RESCHE_SUCCESS' || event.type === 'TRANSPORT_SUCCESS') {
      this.getAppointmentsList();
    }
  }

  /* Function for show detail page */
  public showDetailsPage(item) {
    sessionStorage.setItem('requestAppointment', 'false');
    if (item.PatientID) {
      if (this.userType === 'External') {
        sessionStorage.setItem('previousPatientPage', 'today-dashboard');
        this.router.navigate(['dashboard/common/patient-dashboard/', item.PatientID, 'patient-demographics', item.AppointmentID]);
      } else {
        sessionStorage.setItem('previousPatientPage', 'app-dashboard');
        this.router.navigate(['dashboard/common/patient-dashboard/', item.PatientID, 'patient-demographics', item.AppointmentID]);
      }
    }

  }

  /* Function for show bulk confirm */
  public closeBulkConfirm(event) {
    this.showBulkConfirm = false;
    if (event.action === 'confirm') {
      const sampleArray = [];
      this.appointmentsList.forEach(element => {
        if (element.isSelected) {
          sampleArray.push(element);
        }
      });
      this.showActionPop(sampleArray, event.actionType, 'bulk');
    }
  }
  public checkAMPMvalues() {
    let AMcount = 0;
    let PMcount = 0;
    this.appointmentsList.forEach((element) => {
      if (element.AppointmentTime) {
        const timeGiven = element.AppointmentTime;
        // const UTCdate = new Date(element.AppointmentUTCTime + 'Z');
        // let hours = UTCdate.getHours();

        // let amPm;
        // if (hours < 12) {
        //   amPm = 'AM';
        // } else {
        //   hours = hours - 12;
        //   amPm = 'PM';
        // }

        let amPm;
        let hours;
        let minutes;

        hours = timeGiven.split(':')[0];
        minutes = timeGiven.split(':')[1].split(' ')[0];
        amPm = timeGiven.split(' ')[1];
        // if (hours < 12) {
        //   amPm = 'AM';
        // } else {
        //   hours = hours - 12;
        //   amPm = 'PM';
        // }


        // const AMarray = ['6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM', '12 PM'];
        // const amValue = AMarray.indexOf(hours + ' ' + amPm);
        // if (amValue >= 0) {
        //   AMcount++;
        // }
        // const PMarray = ['1 PM', '2 PM', '3 PM', '4 PM'];
        // const pmValue = PMarray.indexOf(hours + ' ' + amPm);
        // if (pmValue >= 0) {
        //   PMcount++;
        // }

        const AMarray = ['6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM', '12 PM'];
        const amValue = AMarray.indexOf(hours + ' ' + amPm);
        if (amValue >= 0) {
          if (hours === '12' && amPm === 'PM') {
            if (minutes === '00') {
              AMcount++;
            }
          } else {
            AMcount++;
          }
        }

        const PMarray = ['1 PM', '2 PM', '3 PM', '4 PM'];
        const pmValue = PMarray.indexOf(hours + ' ' + amPm);
        if (pmValue >= 0) {
          if (hours === '4' && amPm === 'PM') {
            if (minutes === '00') {
              PMcount++;
            }
          } else {
            PMcount++;
          }
        }
      }
    });
    this.AMRecords = AMcount;
    this.PMRecords = PMcount;
  }
  public isNewOne(item) {
    if (item.IsNewAppointment) {
    return true;
    } else {
      return false;
    }
  }
}
