import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../shared/utils/imports';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  public showUnderConstructionevent = false;

  
  constructor(
    private readonly common: CommonService
  ) { }

  ngOnInit() {
  }
  /* Function for navigate to Aran Eye site */
  public gotoAranEye() {
    this.common.openNewTab('http://www.araneye.com/');
  }

  /* Function for navigate to TLC site */
  public gotoTLC() {
    this.common.openNewTab('http://tlccoralgables.com/');
  }

  /* Function for navigate to privacy and policy */
  public gotoPrivacyPolicy() {
    this.common.openNewTab('https://www.internetbrands.com/privacy/privacy-highlights.html');
  }

  /* Function for navigate to terms and conditions */
  public gotoTermsAndConditions() {
    this.common.openNewTab('https://www.internetbrands.com/ibterms/');
  }

  /* Function for navigate to Health policy */
  public gotoHealthPolicy() {
    this.common.openNewTab('https://www.internetbrands.com/ibterms/supplementalhealthterms/index.php');
  }

  /* Function for navigate to cookie policy*/
  public gotoCookiPolicy() {
    this.common.openNewTab('https://www.internetbrands.com/privacy/cookie-policy.html');
  }

  /* Function for navigate to survey */
  public gotoSurvey() {
    console.log('SURVEY');
  }

  /* Function for navigate to patient tools */
  public gotoPatientTools() {
    console.log('PATIENT TOOLS');
  }

  /* Function for Show page under constrution */
  public showUnderConstruction() {
    this.showUnderConstructionevent = true;
  }

  /* Function for back to page */
  public backToPage() {
    this.showUnderConstructionevent = false;
  }
}
